//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './style/detalle-oferta-style.scss';

export default {
  name: 'DetalleOferta',

  props: {
    selected: {
      type: Boolean,
      default: false
    },
    formularioContrato: {
      type: Boolean,
      default: false
    },
    oferta: {
      type: Object,
      default: () => ({})
    },
    allSteps: {
      type: Object
    }
  },
  data() {
    return {
      dataToSend: {
        ofertaName: '',
        ofertaType: '',
        frases: [],
        values: {
          feesEnergy: {
            price: {
              p1: 0,
              p2: 0,
              p3: 0,
              p4: 0,
              p5: 0,
              p6: 0
            }
          },
          powerMargin: {
            price: {
              p1: 0,
              p2: 0,
              p3: 0,
              p4: 0,
              p5: 0,
              p6: 0
            }
          }
        }
      }
    };
  },
  created() {
    if (this.formularioContrato) {
      this.selected = true;
      this.setDataContrato();
      return;
    }

    this.setDataOfertas();
  },
  methods: {
    fixed(value) {
      if (value === 0 || value === null || value === undefined || value === '0') return 0;
      return value.toFixed(5);
    },
    // Seteo de datos cuando viene de las ofertas
    setDataOfertas() {
      this.dataToSend.ofertaName = this.oferta.alias;
      this.dataToSend.ofertaType = this.oferta.type;
      this.dataToSend.frases = this.oferta.frases;
      this.dataToSend.values.feesEnergy.price.p1 =
        this.oferta.values.feesEnergy.price.p1 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p1) / 1000;
      this.dataToSend.values.feesEnergy.price.p2 =
        this.oferta.values.feesEnergy.price.p2 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p2) / 1000;
      this.dataToSend.values.feesEnergy.price.p3 =
        this.oferta.values.feesEnergy.price.p3 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p3) / 1000;
      this.dataToSend.values.feesEnergy.price.p4 =
        this.oferta.values.feesEnergy.price.p4 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p4) / 1000;
      this.dataToSend.values.feesEnergy.price.p5 =
        this.oferta.values.feesEnergy.price.p5 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p5) / 1000;
      this.dataToSend.values.feesEnergy.price.p6 =
        this.oferta.values.feesEnergy.price.p6 +
        this.checkNumber(this.oferta.values.feesEnergy.selected.p6) / 1000;

      this.dataToSend.values.powerMargin.price.p1 =
        this.oferta.values.powerMargin.price.p1 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p1);
      this.dataToSend.values.powerMargin.price.p2 =
        this.oferta.values.powerMargin.price.p2 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p2);
      this.dataToSend.values.powerMargin.price.p3 =
        this.oferta.values.powerMargin.price.p3 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p3);
      this.dataToSend.values.powerMargin.price.p4 =
        this.oferta.values.powerMargin.price.p4 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p4);
      this.dataToSend.values.powerMargin.price.p5 =
        this.oferta.values.powerMargin.price.p5 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p5);
      this.dataToSend.values.powerMargin.price.p6 =
        this.oferta.values.powerMargin.price.p6 +
        this.checkNumber(this.oferta.values.powerMargin.selected.p6);
    },
    // Seteo de datos para cuando viene del formulario de contrato
    setDataContrato() {
      this.dataToSend.ofertaName = this.oferta.aliasTarifa;
      this.dataToSend.ofertaType = this.oferta.tipoTarifa;
      this.dataToSend.frases = this.oferta.frases;
      this.dataToSend.values.feesEnergy.price.p1 = this.oferta.precioEnergia.p1;
      this.dataToSend.values.feesEnergy.price.p2 = this.oferta.precioEnergia.p2;
      this.dataToSend.values.feesEnergy.price.p3 = this.oferta.precioEnergia.p3;
      this.dataToSend.values.feesEnergy.price.p4 = this.oferta.precioEnergia.p4;
      this.dataToSend.values.feesEnergy.price.p5 = this.oferta.precioEnergia.p5;
      this.dataToSend.values.feesEnergy.price.p6 = this.oferta.precioEnergia.p6;
      this.dataToSend.values.powerMargin.price.p1 = this.oferta.precioPotencias.p1;
      this.dataToSend.values.powerMargin.price.p2 = this.oferta.precioPotencias.p2;
      this.dataToSend.values.powerMargin.price.p3 = this.oferta.precioPotencias.p3;
      this.dataToSend.values.powerMargin.price.p4 = this.oferta.precioPotencias.p4;
      this.dataToSend.values.powerMargin.price.p5 = this.oferta.precioPotencias.p5;
      this.dataToSend.values.powerMargin.price.p6 = this.oferta.precioPotencias.p6;
    },
    customPrice() {
      const costeConsumo =
        (this.allSteps['2'].externalData.consumo.consumoP1 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p1 +
        (this.allSteps['2'].externalData.consumo.consumoP2 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p2 +
        (this.allSteps['2'].externalData.consumo.consumoP3 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p3 +
        (this.allSteps['2'].externalData.consumo.consumoP4 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p4 +
        (this.allSteps['2'].externalData.consumo.consumoP5 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p5 +
        (this.allSteps['2'].externalData.consumo.consumoP6 /
          1000 /
          this.allSteps['2'].externalData.consumo.dias) *
          this.dataToSend.values.feesEnergy.price.p6;
      if (Number.isNaN(costeConsumo)) return '-';
      const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
      }).format(costeConsumo * 30);

      this.$emit('price', formatter.replace('€', ''));
      return formatter;
    },
    checkNumber(number) {
      if (number === '' || number === null || number === undefined) {
        return 0;
      }

      return number;
    }
  }
};
