const ValidateForm = {
  validate(vm, blockFields = [], indice = null) {
    const result = Object.keys(vm.$refs).reduce((accumulator, field) => {
      const fieldCheck = indice !== null ? vm.$refs[field][indice] : vm.$refs[field];
      if (!blockFields.includes(field) && fieldCheck && fieldCheck.checkInvalid().status) {
        return accumulator + 1;
      }
      if (blockFields.includes(field)) {
        const resultIn = Object.keys(fieldCheck.$refs).reduce((accumulatorIn, fieldIn) => {
          const corroboreCheck = !!(
            fieldCheck.$refs[fieldIn] && fieldCheck.$refs[fieldIn].checkInvalid().status
          );

          if (
            fieldCheck.$refs[fieldIn] &&
            fieldCheck.$refs[fieldIn].checkInvalid().status &&
            corroboreCheck
          ) {
            return accumulatorIn + 1;
          }
          return accumulatorIn;
        }, 0);
        return accumulator + resultIn;
      }
      return accumulator;
    }, 0);

    return result;
  },
  checkChildren(childrenName, childInstance) {
    let elemento = null;
    if (childInstance.$children.length > 0) {
      childInstance.$children.forEach(element => {
        if (element.$options.name === childrenName) {
          elemento = element;
        }

        elemento =
          this.checkChildren(childrenName, element) !== null
            ? this.checkChildren(childrenName, element)
            : elemento;

        if (elemento !== null) {
          return elemento;
        }
        return elemento;
      });
    }

    return elemento;
  },
  numberFormat(value) {
    if (value === '' || value === null || value === undefined) return value;

    return Number(value).toLocaleString('de-DE', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    });
    // if (num === '' || num === null || num === undefined) return '';
    // // Capturamos el numero y lo convertimos a cadena de texto
    // const numero = num.toString();
    // // Variable que contendra el resultado final
    // let resultado = '';
    // let nuevoNumero = '';
    // // Si el numero empieza por el valor "-" (numero negativo)
    // // if (numero[0] === '-') {
    // // Cogemos el numero eliminando los posibles puntos que tenga, y sin
    // // el signo negativo
    // // nuevoNumero = numero.replace(/\./g, '').substring(1);
    // // } else {
    // // Cogemos el numero eliminando los posibles puntos que tenga
    // // nuevoNumero = numero.replace(/\./g, '');
    // // }

    // // Si tiene decimales, se los quitamos al numero
    // if (numero.indexOf('.') >= 0) nuevoNumero = numero.substring(0, numero.indexOf('.'));
    // else resultado = numero;

    // // Ponemos un punto cada 3 caracteres
    // if (nuevoNumero !== '' && nuevoNumero !== '0') {
    //   // eslint-disable-next-line no-plusplus
    //   for (let j = 0, i = nuevoNumero.length - 1; i >= 0; i--, j++)
    //     resultado = nuevoNumero.charAt(i) + (j > 0 && j % 3 === 0 ? '.' : '') + resultado;
    // }

    // if ((nuevoNumero === '' || nuevoNumero === '0') && numero.indexOf('.') >= 0) {
    //   resultado = '0';
    // }

    // // Si tiene decimales, se lo añadimos al numero una vez forateado con
    // // los separadores de miles
    // if (numero.indexOf('.') >= 0) {
    //   const decimales = numero.split('.');
    //   resultado = `${resultado},${decimales[1]}`;
    // }

    // if (numero[0] === '-') {
    //   // Devolvemos el valor añadiendo al inicio el signo negativo
    //   resultado = `-${resultado}`;
    //   return resultado;
    // }
    // return resultado;
  }
};

export default ValidateForm;
