//
//
//
//

export default {
  props: {
    inline: Boolean
  }
};
