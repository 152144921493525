//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInputTitle from './form-input-title.vue';

export default {
  components: {
    FormInputTitle
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator: v => ['text', 'number', 'password'].includes(v)
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: [Number],
      required: false
    },
    value: {
      type: [String, Number]
    },
    name: {
      type: String,
      required: false
    },
    readonly: {
      type: [Boolean, String],
      default: false
    },
    invalid: {
      type: [String, undefined],
      default: ''
    },
    autocomplete: String
  },
  methods: {
    handleInput(event) {
      const { type, maxLength } = this;
      let value = String(event.target.value);

      // No permitir mas tamaño de caracteres del permitido
      if (maxLength && value.length > maxLength) {
        value = value.slice(0, maxLength);
        this.$refs.input.value = value;
      }

      // Eliminar que se pueda añadir el exponencial (permitido de forma nativa)
      if (type === 'number' && value !== '') {
        value = String(value).replace(/e/g, '');
      }

      this.$emit('input', value);
    }
  }
};
