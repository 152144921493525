//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import validateForm from '$lib/validate-form.js';

import './style/copiar-oferta-style.scss';

export default {
  name: 'CopiarOferta',
  components: {
    ViuInput,
    ViuLabel
  },
  data() {
    return {
      envioSelected: 'SMS',
      dataToSend: {
        numeroTelefono: '',
        mail: ''
      }
    };
  },
  methods: {
    close() {
      this.$emit('cerrar-modal');
    },
    sendForm() {
      const result = validateForm.validate(this, []);

      if (result > 0) return;

      this.$emit('cerrar-modal');
    }
  }
};
