//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInput from './form/form-input.vue';
import { TARIFA_2_0_TD, TARIFA_3_0_TD } from '../../../../constants/tarifas.js';
import {
  PATRON_POTENCIA_ERROR,
  CAMPO_VACÍO_ERROR,
  DELIMITADOR_DECIMALES_ERROR,
  RANGO_TARIFA_2_0_ERROR,
  MAYOR_0_ERROR,
  SIN_MAYOR_15_ERROR,
  ASCENDENTES_ERROR
} from '../../../../constants/errores.js';

export default {
  components: {
    FormInput
  },
  props: {
    hiredPower: String,
    potenciaContratada: Object,
    tarifaAtr: String,
    channel: String
  },
  data: () => ({
    editandoPotencias: false,
    formErrors: { p1: null, p2: null, p3: null, p4: null, p5: null, p6: null },
    patronPotencias: /^[0-9]{1,11}$|^[0-9]{1,11}([.][0-9]{1,3})?$/
  }),
  computed: {
    potenciasVisibles() {
      const potencias = this.potenciaContratada;
      Object.keys(potencias).forEach(potencia => {
        if (!this.formInputVisible(potencia)) {
          delete potencias[potencia];
        }
      });
      return potencias;
    }
  },
  methods: {
    formInputVisible(nombrePotencia) {
      if (this.tarifaAtr === TARIFA_2_0_TD) {
        const laPotencia = nombrePotencia.toLowerCase();
        return ['p1', 'p2'].includes(laPotencia);
      }
      return true;
    },
    inputVacio(valor) {
      return valor === '';
    },
    incluyeComa(valor) {
      return valor.includes(',');
    },
    isAgentChannel() {
      return this.channel !== 'automatic' && this.channel !== 'manual';
    },
    isManualChannel() {
      return this.channel === 'manual';
    },
    isAutomaticChannel() {
      return this.channel === 'automatic';
    },
    potenciasAscendentes(potencias) {
      const { length } = potencias;
      for (let i = 0; i < length; i += 1) {
        const potenciaSiguiente = potencias[i + 1];
        const potenciaActual = potencias[i];
        if (potenciaSiguiente - potenciaActual < 0) return false;
      }
      return true;
    },
    /* Aseguramos que valores de potencias sean strings trimeados */
    corregirPotencias(lasPotencias) {
      const potencias = lasPotencias;
      const { length } = potencias;
      for (let i = 0; i < length; i += 1) {
        let valor = potencias[i][1];
        valor = String(valor);
        valor = valor.trim();
        potencias[i][1] = valor;
      }
      return potencias;
    },
    validarPotencias() {
      let potencias = Object.entries(this.potenciaContratada); // ej: [['p1', '3.4'], ['p2', '2'], etc.]
      potencias = this.corregirPotencias(potencias);
      const valores = Object.values(this.potenciaContratada).map(valor => parseFloat(valor)); // ej: [3.4, 2, etc.]
      // Reset a null de todos los errores
      potencias.forEach(([nombrePotencia]) => {
        this.formErrors[nombrePotencia] = null;
      });

      if (this.tarifaAtr === TARIFA_2_0_TD) {
        this.validarTarifa20(potencias);
      } else if (this.tarifaAtr === TARIFA_3_0_TD) {
        this.validarTarifa30(potencias, valores);
      } else {
        this.validarTarifa6X(potencias, valores);
      }
    },
    validarTarifa20(lasPotencias) {
      const potencias = lasPotencias.slice(0, 2); // Importante para que no valide las otras 4 (que darían error al ser 0)
      potencias.forEach(([nombrePotencia, value]) => {
        if (this.inputVacio(value)) {
          this.formErrors[nombrePotencia] = CAMPO_VACÍO_ERROR;
        } else if (this.incluyeComa(value)) {
          this.formErrors[nombrePotencia] = DELIMITADOR_DECIMALES_ERROR;
        } else if (parseFloat(value) <= 0 || parseFloat(value) > 15) {
          this.formErrors[nombrePotencia] = RANGO_TARIFA_2_0_ERROR;
        } else if (!this.patronPotencias.test(value)) {
          this.formErrors[nombrePotencia] = PATRON_POTENCIA_ERROR;
        }
      });
    },
    validarTarifa30(potencias, valores) {
      let hayErrores = false;
      potencias.forEach(([nombrePotencia, value]) => {
        if (this.inputVacio(value)) {
          this.formErrors[nombrePotencia] = CAMPO_VACÍO_ERROR;
          hayErrores = true;
        } else if (this.incluyeComa(value)) {
          this.formErrors[nombrePotencia] = DELIMITADOR_DECIMALES_ERROR;
        } else if (parseFloat(value) <= 0) {
          this.formErrors[nombrePotencia] = MAYOR_0_ERROR;
          hayErrores = true;
        } else if (!this.patronPotencias.test(value)) {
          this.formErrors[nombrePotencia] = PATRON_POTENCIA_ERROR;
          hayErrores = true;
        }
      });
      if (hayErrores) return;
      if (!this.potenciasAscendentes(valores)) {
        potencias.forEach(([nombrePotencia]) => {
          this.formErrors[nombrePotencia] = ASCENDENTES_ERROR;
        });
      } else if (valores.every(val => val <= 15)) {
        potencias.forEach(([nombrePotencia]) => {
          this.formErrors[nombrePotencia] = SIN_MAYOR_15_ERROR;
        });
      }
    },
    validarTarifa6X(potencias, valores) {
      let hayErrores = false;
      potencias.forEach(([nombrePotencia, value]) => {
        if (this.inputVacio(value)) {
          this.formErrors[nombrePotencia] = CAMPO_VACÍO_ERROR;
          hayErrores = true;
        } else if (this.incluyeComa(value)) {
          this.formErrors[nombrePotencia] = DELIMITADOR_DECIMALES_ERROR;
        } else if (parseFloat(value) <= 0) {
          this.formErrors[nombrePotencia] = MAYOR_0_ERROR;
          hayErrores = true;
        } else if (!this.patronPotencias.test(value)) {
          this.formErrors[nombrePotencia] = PATRON_POTENCIA_ERROR;
          hayErrores = true;
        }
      });
      if (!hayErrores && !this.potenciasAscendentes(valores)) {
        potencias.forEach(([nombrePotencia]) => {
          this.formErrors[nombrePotencia] = ASCENDENTES_ERROR;
        });
      }
    },
    guardarPotencias() {
      this.validarPotencias();
      const errores = Object.values(this.formErrors);
      const hayErrores = errores.some(err => err !== null);
      if (!hayErrores) {
        const potencias = {
          potencias: this.potenciaContratada
        };
        this.editandoPotencias = false;
        this.$emit('cambiar-potencias', potencias);
      }
    }
  }
};
